import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgPeacockLogo from '../img/logo_peacock.jpg'

const Page = () => (
  <Layout pageName="men">
    <SEO title="Men" />
    <div id="primary" className="content-area">
      <main id="main" className="site-main" role="main">
        <article id="post-37" className="post-37 page type-page status-publish hentry">
          <div className="image-container"><img
            src={imgPeacockLogo} /></div>
          <div className="text-flow">
            <p>Men are increasingly interested in facial aesthetics treatments as they realize that a rugged George
              Clooney look is one thing, but a craggy, wrinkly face is quite another! As Forbes magazine says: “Every
              guy needs to admit this one honest truth: Feeling that you're getting old sucks, no matter what your age.
              We all look in the mirror every morning and judge ourselves by how we look”</p>

            <p>Men have a different set of issues to women when it comes to facial aesthetics. They tend to have thicker
              skin and stronger facial muscles. And they are often focused on different things: a more chiseled chin,
              sharper nose, or defined cheekbones. Botulinum toxin can also be an effective treatment for excessive
              sweating under the arm pits.</p>

            <p>To address these issues, it helps to have someone more skilled in difficult facial and oral surgery than
              the typical part-time nurse at a beauty parlour. See Dr Neggin Heidari-Robinson's qualifications for the
              job <a href="/about-us" title="Link to 'About Us'">here</a>. And, if you might be a bit embarrassed by a
              trip to a beauty parlour, we can provide you with the perfect discreet <a href="/contact">venue</a>: what
              could be more natural than a trip to the dentists? We can even check your teeth out while you're there.
            </p>

            <p>You might consider the following treatments:</p>

            <ul className="list-dot list-indent">
              <li><a href="/treatments-and-prices/#botuminum-toxin-for-men">Botulinum Toxin — or “brotox” for men!</a> —
                You will find that you will need more than for women, due to your thicker skin and stronger facial
                muscles. Botox works by relaxing selected muscles that pull the skin causing lines and wrinkles. As the
                muscles relax, the lines will soften or even disappear completely.
              </li>
              <li><a href="/treatments-and-prices/#dermal-fillers">Dermal Fillers</a> — men are as prone to wrinkles as
                women, but it requires skill to maintain the sharp look of a man's face, versus the more rounded look of
                a woman's. Dermal fillers can also be used to reduce the appearance of scarring.
              </li>
              <li><a href="/treatments-and-prices/#hyperhidrosis">Hyperhidrosis</a> — botox for excessive sweating. With
                this treatment, botox is injected into the skin to stop sweating for 3-6 months.
              </li>
            </ul>


            <p>It is important to note that all these treatments are temporary in nature (typically lasting 3-6 months).
              This is an advantage versus more invasive surgical procedures, where you may be left with the effects (for
              good and possibly bad) for a lifetime. But it is important to note that if you want to maintain the
              effects, regular treatment is required.</p>

            <p>Whatever, you might be interested in, the first step is a consultation with <a href="/about-us">Dr
              Neggin</a> in either of our two <a href="/contact">surgeries</a>.</p>
          </div>
        </article>
      </main>
    </div>
  </Layout>
)

export default Page